import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostCard from "../components/post-card"
import ContentWrapper from "../components/content-wrapper"
import { arrows, arrow } from "./projects.module.css"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const Projects = (props) => {
  const { data, pageContext } = props
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0
  let previousLink = ""
  let nextLink = ""

  if (pageContext.currentPage === 2) {
    previousLink = `${pageContext.basePath}`
  }
  if (pageContext.currentPage > 2) {
    previousLink = `${pageContext.basePath}/${pageContext.currentPage - 1}`
  }

  if (pageContext.currentPage < pageContext.numPages) {
    nextLink = `${pageContext.basePath}/${pageContext.currentPage + 1}`
  }

  return (
    <Layout bigImage={false} title={siteTitle}>
      <Seo
        title="Projetos multimédia"
        keywords={[`vídeo`, `multimédia`, `edição`]}
      />
      <ContentWrapper>
        <div className="post-content page-template no-image">
          <div className="post-content-body">
            <h1>Projetos</h1>
          </div>
        </div>
        <div className="post-feed">
          {posts.map(({ node }) => {
            postCounter++
            return (
              <PostCard
                key={node.fields.slug}
                count={postCounter}
                node={node}
                postClass={`post`}
              />
            )
          })}
        </div>
        <div className={arrows}>
          {previousLink && (
            <Link className={arrow} to={previousLink}>
              Anterior
            </Link>
          )}
          {nextLink && (
            <Link className={arrow} to={nextLink}>
              Seguinte
            </Link>
          )}
          <div>
            Página {pageContext.currentPage} de {pageContext.numPages}
          </div>
        </div>
      </ContentWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 100
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`

export default Projects
